@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #232222;
    --color-bg2: #2d2a2a;
    --color-primary: #cf9c63;
    --color-primary2: #d99447;
    --color-white: #ffffff;
    --color-white2: #c8c8c8;



}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    overflow-x: hidden;
}

/* Buttons */

.download-cv {
    font-family: 'josefin sans', sans-serif;
    background-color: transparent;
    text-decoration: none;
    border-radius: 8px;
    border: 2px #cf9c63 solid;
    color: #fff;
    padding: 8px 24px;
    transition: ease-in 400ms;
}
.download-cv a {
    text-decoration: none;
    color: #fff;
}
.download-cv:hover {
    background-color: #cf9c63;
}

.about-me {
    font-family: 'josefin sans', sans-serif;
    background-color: #cf9c63;
    text-decoration: none;
    border-radius: 8px;
    border: 2px #cf9c63 solid;
    color: #fff;
    padding: 8px 24px;
    margin-right: 16px;
    transition: ease-out 500ms;
}
.about-me:hover {
    box-shadow: 0 0 15px 0 #cf9c63;
}
.about-me a {
    text-decoration: none;
    color: #fff;
}

h1 {
    font-family: 'poppins', sans-serif;
    color: white;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.5;
    margin: 16px 0;
}

h2 {
    font-family: 'josefin sans', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    color: #fff;
}

h3 {
    font-family: 'josefin sans', sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    color: #fff;
}

.features p {
    font-family: 'poppins', sans-serif;
    font-size: 1rem;
    color: #c2c2c2;
    margin-top: 16px;
}

.orange-text {
    color: #cf9c63;
}

section {
    transition: all 500ms ease;
}