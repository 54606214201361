/* Hero */

#hero {
    padding: 11rem 5rem;
    background-color: #232222;
}

.about-media .react-reveal {
    font-family: 'josefin sans', sans-serif;
    background-color: #cf9c63;
    text-decoration: none;
    border-radius: 8px;
    border: 2px #cf9c63 solid;
    color: #fff;
    padding: 8px 24px;
    margin-right: 16px;
    transition: ease-out 500ms;
}
.about-media .react-reveal:hover {
    box-shadow: 0 0 15px 0 #cf9c63;
}

.about-media .react-reveal a {
    text-decoration: none;
    color: #fff;
}

.fa-brands {
    color: #fff;
    margin: 0 8px;
}

.hero-socials {
    transition: all 0.3s ease;
    margin: 0 10px;
}
.hero-socials:hover {
    padding: 4px;
    background-color: #cf9c63;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 #fff;
}

.hero-writeup {
    padding-top: 40px;
}

h3 span {
    color: var(--color-primary);
    margin-left: 4px;
}

.hero-p {
    font-family: 'poppins', sans-serif;
    color: #c8c8c8;
    font-size: 1rem;
    margin:24px 0;
    line-height: 1.6;
}

.about-media {
    padding: 16px 0 24px;
    margin-bottom: 8px;
}

.image-icons {
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;
}

.hero-image {
    width: 70%;
    opacity: 0;
    transform: scale(0.6);
    animation: fade 1s linear forwards;
}

.my-image {
    width: 100%;
}

.invision-icon {
    display: inline-block;
    position: absolute;
    right: 10%;
    top: 40%;
}

.illustrator-icon {
    display: inline-block;
    position: absolute;
    left: 18%;
    top: 5%;
}

.figma-icon {
    display: inline-block;
    position: absolute;
    left: 18%;
    bottom: 5%;
}

.shape-icon {
    display: inline-block;
    position: absolute;
    right: 10%;
    bottom: 70%;
}

.my-shape {
    width: 90%;
    opacity: 80%;
}

.middle-icon {
    display: inline-block;
    position: absolute;
    left: -4%;
    bottom: -10%;
}

/* Navbar animation */

@keyframes fade {
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes bounce {
    from {
        transform: translateY(-20px);
    }

    to {
        transform: translateY(0);
    }
}

.hero-svg {
    animation: bounce 3s linear 0s infinite alternate;
}

/* Hero @media */

@media screen and (max-width: 1028px) {
    
    #hero {
        padding: 1rem;
        text-align: center;
    }

    .hero-writeup {
        padding-top: 70px;
    }

    br {
        display: none;
    }

    .title-image {
        position: static;
        transform: rotate(0deg);
        width: 60%;
    }

    .about-writeup {
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: 8% 8% 0% 4%;
    } 

    .shape-icon {
        display: none;
    }

    .middle-icon {
        display: none;
    }

    .hero-p {
        padding: 0 8%;
    }
}

@media screen and (max-width: 600px) {

    #hero {
        padding: 2rem 1rem;   
    }

    .hero-writeup {
        padding-top: 60px;
    }

    .hero-image {
        width: 85%;
    }

    .hero-svg {
        width: 10%;
    }

    .hero-p {
        padding: 0;
    }
}